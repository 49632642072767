@import '../../sass/variables';

.wrapper {
  position: relative;
  z-index: 1;
  display: inline-block;
  max-width: 100%;
}

.decoration {
  position: absolute;
  z-index: -1;
  border-radius: 15px;
  opacity: 0;
}

// Sizes
.sm {
  width: 100px;
  height: 100px;
}

.md {
  width: 150px;
  height: 150px;
}

.lg {
  width: 200px;
  height: 200px;
}

// Positions
.top-left {
  &.sm {
    top: -10px;
    left: -10px;
  }

  &.md {
    top: -20px;
    left: -20px;
  }

  &.lg {
    top: -30px;
    left: -30px;
  }
}

.top-right {
  &.sm {
    top: -10px;
    right: -10px;
  }

  &.md {
    top: -20px;
    right: -20px;
  }

  &.lg {
    top: -30px;
    right: -30px;
  }
}

.bottom-left {
  &.sm {
    bottom: -10px;
    left: -10px;
  }

  &.md {
    bottom: -20px;
    left: -20px;
  }

  &.lg {
    bottom: -30px;
    left: -30px;
  }
}

.bottom-right {
  &.sm {
    right: -10px;
    bottom: -10px;
  }

  &.md {
    right: -20px;
    bottom: -20px;
  }

  &.lg {
    right: -30px;
    bottom: -30px;
  }
}

// Themes
.blue {
  background: $colorBlue100;
}

.yellow {
  background: $colorYellow100;
}

.purple {
  background: rgba($colorPurple400, 0.1);
}

@include media-breakpoint-up(sm) {
  .decoration {
    opacity: 1;
  }
}

@include media-breakpoint-up(lg) {
  // Sizes
  .sm {
    width: 150px;
    height: 150px;
  }

  .md {
    width: 250px;
    height: 250px;
  }

  .lg {
    width: 300px;
    height: 300px;
  }

  // Positions
  .top-left {
    &.sm {
      top: -30px;
      left: -30px;
    }

    &.md {
      top: -40px;
      left: -40px;
    }

    &.lg {
      top: -50px;
      left: -50px;
    }
  }

  .top-right {
    &.sm {
      top: -30px;
      right: -30px;
    }

    &.md {
      top: -40px;
      right: -40px;
    }

    &.lg {
      top: -50px;
      right: -50px;
    }
  }

  .bottom-left {
    &.sm {
      bottom: -30px;
      left: -30px;
    }

    &.md {
      bottom: -40px;
      left: -40px;
    }

    &.lg {
      bottom: -50px;
      left: -50px;
    }
  }

  .bottom-right {
    &.sm {
      right: -30px;
      bottom: -30px;
    }

    &.md {
      right: -40px;
      bottom: -40px;
    }

    &.lg {
      right: -50px;
      bottom: -50px;
    }
  }
}
